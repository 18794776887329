import React from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Button,
  VStack,
  HStack,
  Spacer,
  Heading,
  Image,
  Link
} from '@chakra-ui/react';
import LogoWhite from './Assets/logo_white.png'
import DownloadIos from './Assets/download_ios.png'
import DownloadBackground from './Assets/download_bg.jpeg'
import iOSQRCode from './Assets/iosqrcode.png'
import { auth, fire_tracking_event } from '../firebase/firebase';


const DownloadAppModal = ({ isOpen, onClose, message, referralCode }) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  const user = auth.currentUser;
  console.log(user)
  if (isMobile) {
    return (
      <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      width="343px" height="399px"
      >
        <ModalOverlay />
        <ModalContent borderRadius={ 8 }
        width="343px" height="399px"
        >
          <ModalBody 
          width="100%" height="100%"
          backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${DownloadBackground})`} 
          backgroundSize="cover"
          >
            <VStack spacing={4} alignItems='center'>
              <Image src={LogoWhite} alt="Logo" />
              <Heading as="h2" size="xl" textAlign="center" mb={4} color='white'>
              Hi there {user.displayName}!
              </Heading>
              <Text color='white'>{message}</Text>
              <Text color='white'>Download the VaicAI app to get started!</Text>
              <Link href="https://apps.apple.com/us/app/vaicai/id6443838892" isExternal onClick={() => fire_tracking_event(`referral.download_app.${referralCode}`)}>
                  <Image src={DownloadIos} alt="App Store" />
                </Link>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  } else {
      return (
        <Modal isOpen={isOpen} onClose={onClose} width="436px" height="472px" borderRadius={8}>
          <ModalOverlay />
          <ModalContent
            borderRadius={8}
            width="436px" height="472px"
           >
            <ModalBody 
            width="100%" height="100%" 
            backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${DownloadBackground})`} 
            backgroundSize="cover"
            >
              <VStack spacing={4} alignItems='center'>
                <Image src={LogoWhite} alt="Logo" />
                <Heading as="h2" size="xl" textAlign="center" mb={4} color='white'>
                  Hi there {user.displayName}!
                </Heading>
                <Text color='white'>{message}</Text>
                <Text color='white'>Download the VaicAI app to get started!</Text>
                <Link href="https://apps.apple.com/us/app/vaicai/id6443838892" isExternal onClick={() => fire_tracking_event(`referral.download_app.${referralCode}`)}>
                    <Image src={iOSQRCode} alt="App Store" width="120px" height="120px" borderRadius={8}/>
                  </Link>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      );
  }
};


export default DownloadAppModal;