import './App.css';
import React from "react";
import Landing from './components/Landing';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';


function App() {
  return <ChakraProvider theme={theme}>

    <Landing landingPath="home"/>
    {/* <EnterPhone state="input" app={app} onSubmitOTP= {() => {}} recaptchaResponse={null}  onRecaptchaResponse={() => {}} onConfirmationResult={() => {console.log("wow")}}/> */}
    </ChakraProvider>
}

export default App;