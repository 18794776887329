import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-app.js";
import { getAuth, updateProfile, signInWithPhoneNumber, RecaptchaVerifier } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";
import { getAnalytics, logEvent } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-analytics.js";
import { getStorage, ref, uploadBytes, getDownloadURL } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-storage.js";
const firebaseConfig = {
    apiKey: "AIzaSyBOP7ypkIE9bBPfJw2uiacBpH2UlWB7tLE",
    authDomain: "vaicai-9e0ef.firebaseapp.com",
    databaseURL: "https://vaicai-9e0ef-default-rtdb.firebaseio.com",
    projectId: "vaicai-9e0ef",
    storageBucket: "vaicai-9e0ef.appspot.com",
    messagingSenderId: "437870072240",
    appId: "1:437870072240:web:278e6eae72a28b1fdc0675",
    measurementId: "G-CCF5JSNB2P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


// Initialize Analytics
const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;
/**
 * Fires a custom tracking event to Firebase Analytics.
 * @param {string} event_id - The name/ID of the event to log.
 * @param {Object} [params={}] - Optional parameters to include with the event.
 */
export function fire_tracking_event(event_id, params = {}) {
  logEvent(analytics, event_id, params);
}


export { auth, app, analytics, storage, ref, uploadBytes, RecaptchaVerifier, signInWithPhoneNumber, updateProfile, getDownloadURL };