import { useState, useRef, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Text,
  Box,
  Button,
  Input,
  HStack,
} from '@chakra-ui/react';
import ProfilePhotoSection from './ProfilePhotoSection';
import { useMediaQuery } from '@chakra-ui/react';
import CountrySelect from './CustomComponents/CountrySelect';
import { storage, ref, uploadBytes, RecaptchaVerifier, signInWithPhoneNumber, updateProfile, getDownloadURL } from '../firebase/firebase';

const countryCodes = [
    { name: 'United States', code: '+1', flagCode: 'US' },
    { name: 'United Kingdom', code: '+44', flagCode: 'GB' },
    { name: 'Trinidad & Tobago', code: '+1868', flagCode: 'TT' },
    { name: 'Canada', code: '+1', flagCode: 'CA' },
    { name: 'Australia', code: '+61', flagCode: 'AU' },
    { name: 'Brazil', code: '+55', flagCode: 'BR' },
    { name: 'Jamaica', code: '+1876', flagCode: 'JM' },
    { name: 'United Arab Emirates', code: '+971', flagCode: 'AE' },
    { name: 'Saudi Arabia', code: '+966', flagCode: 'SA' },
    { name: 'Qatar', code: '+974', flagCode: 'QA' },
    { name: 'European Union', code: '', flagCode: 'EU' }, // No single calling code
    { name: 'South Africa', code: '+27', flagCode: 'ZA' },
    { name: 'Finland', code: '+358', flagCode: 'FI' },
    { name: 'Spain', code: '+34', flagCode: 'ES' },
    { name: 'Italy', code: '+39', flagCode: 'IT' },
    { name: 'Anguilla', code: '+1264', flagCode: 'AI' },
    { name: 'Argentina', code: '+54', flagCode: 'AR' },
    { name: 'Antigua & Barbuda', code: '+1268', flagCode: 'AG' },
    { name: 'Barbados', code: '+1246', flagCode: 'BB' },
    { name: 'Belgium', code: '+32', flagCode: 'BE' },
    { name: 'Belize', code: '+501', flagCode: 'BZ' },
    { name: 'Bermuda', code: '+1441', flagCode: 'BM' },
    { name: 'Bolivia', code: '+591', flagCode: 'BO' },
    { name: 'British Virgin Islands', code: '+1284', flagCode: 'VG' },
    { name: 'Chile', code: '+56', flagCode: 'CL' },
    { name: 'Costa Rica', code: '+506', flagCode: 'CR' },
    { name: 'Croatia', code: '+385', flagCode: 'HR' },
    { name: 'Czechia', code: '+420', flagCode: 'CZ' },
    { name: 'Denmark', code: '+45', flagCode: 'DK' },
    { name: 'Dominica', code: '+1767', flagCode: 'DM' },
    { name: 'Dominican Republic', code: '+1', flagCode: 'DO' }, // Part of NANP
    { name: 'Ecuador', code: '+593', flagCode: 'EC' },
    { name: 'El Salvador', code: '+503', flagCode: 'SV' },
    { name: 'Fiji', code: '+679', flagCode: 'FJ' },
    { name: 'France', code: '+33', flagCode: 'FR' },
    { name: 'Germany', code: '+49', flagCode: 'DE' },
    { name: 'Greece', code: '+30', flagCode: 'GR' },
    { name: 'Grenada', code: '+1473', flagCode: 'GD' },
    { name: 'Guatemala', code: '+502', flagCode: 'GT' },
    { name: 'Guyana', code: '+592', flagCode: 'GY' },
    { name: 'Honduras', code: '+504', flagCode: 'HN' },
    { name: 'Hong Kong', code: '+852', flagCode: 'HK' },
    { name: 'Hungary', code: '+36', flagCode: 'HU' },
    { name: 'Iceland', code: '+354', flagCode: 'IS' },
    { name: 'India', code: '+91', flagCode: 'IN' },
    { name: 'Israel', code: '+972', flagCode: 'IL' },
    { name: 'Japan', code: '+81', flagCode: 'JP' },
    { name: 'Jordan', code: '+962', flagCode: 'JO' },
    { name: 'Lithuania', code: '+370', flagCode: 'LT' },
    { name: 'Maldives', code: '+960', flagCode: 'MV' },
    { name: 'Mexico', code: '+52', flagCode: 'MX' },
    { name: 'Montserrat', code: '+1664', flagCode: 'MS' },
    { name: 'Morocco', code: '+212', flagCode: 'MA' },
    { name: 'Netherlands', code: '+31', flagCode: 'NL' },
    { name: 'New Zealand', code: '+64', flagCode: 'NZ' },
    { name: 'Nicaragua', code: '+505', flagCode: 'NI' },
    { name: 'Nigeria', code: '+234', flagCode: 'NG' },
    { name: 'Norway', code: '+47', flagCode: 'NO' },
    { name: 'Pakistan', code: '+92', flagCode: 'PK' },
    { name: 'Panama', code: '+507', flagCode: 'PA' },
    { name: 'Paraguay', code: '+595', flagCode: 'PY' },
    { name: 'Poland', code: '+48', flagCode: 'PL' },
    { name: 'Portugal', code: '+351', flagCode: 'PT' },
    { name: 'Romania', code: '+40', flagCode: 'RO' },
    { name: 'Singapore', code: '+65', flagCode: 'SG' },
    { name: 'Sri Lanka', code: '+94', flagCode: 'LK' },
    { name: 'St. Kitts & Nevis', code: '+1869', flagCode: 'KN' },
    { name: 'St. Lucia', code: '+1758', flagCode: 'LC' },
    { name: 'St. Vincent & Grenadines', code: '+1784', flagCode: 'VC' },
    { name: 'Suriname', code: '+597', flagCode: 'SR' },
    { name: 'Sweden', code: '+46', flagCode: 'SE' },
    { name: 'Switzerland', code: '+41', flagCode: 'CH' },
    { name: 'Taiwan', code: '+886', flagCode: 'TW' },
    { name: 'Thailand', code: '+66', flagCode: 'TH' },
    { name: 'Türkiye', code: '+90', flagCode: 'TR' },
    { name: 'Turks & Caicos Islands', code: '+1649', flagCode: 'TC' },
    { name: 'Uruguay', code: '+598', flagCode: 'UY' },
    { name: 'U.S. Virgin Islands', code: '+1340', flagCode: 'VI' }
];

export default function SignUpModal({ isOpen, onClose, auth, app, onComplete, referralCode}) {
  const [viewState, setViewState] = useState('phoneInput');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName]   = useState('');
  const [email, setEmail]         = useState('');
  const [location, setLocation]   = useState('United States');
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneConfirmationResult, setPhoneConfirmationResult] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  
  const handleRemoveImage = useCallback(() => {
    setSelectedImage(null);
  }, []);

  // The six-character OTP array
  const [otpCode, setOtpCode] = useState(Array(6).fill(''));
  const [currentOTPIndex, setCurrentOTPIndex] = useState(0);
  const [resendTimer, setResendTimer] = useState(48);

  const inputRefs = useRef([]);
  const modalRef = useRef(null);
  const hiddenInputRef = useRef(null); // For autofill capturing

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+\d{10,15}$/;
    return phoneRegex.test(phoneNumber);
  }

  const validateEmail = (email) => {
    if (email === "" ){
        return true;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  }

  const saveFirebase = useCallback(async () => {
    console.log("saveFirebase");
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error("No user is signed in.");
      return;
    }

    let photoPath = null;
    // Upload profile picture if available
    if (selectedImage) {
      try {
        const storageRef = ref(storage, `profile/images/${currentUser.uid}.jpg`);
        
        // Ensure selectedImage is a valid File/Blob
        // If it's a File from a file input, this is fine.
        // If it's base64, convert it to a Blob before upload.
        
        const metadata = {
          contentType: 'image/jpeg' // use image/jpeg if it's a jpeg
        };

        await uploadBytes(storageRef, selectedImage, metadata);
        photoPath = `profile/images/${currentUser.uid}.jpg`;
        const downloadURL = await getDownloadURL(storageRef);
        console.log("Profile image uploaded at:", photoPath);
        console.log("Profile image download URL:", downloadURL);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.log("No image selected, skipping upload.");
      photoPath = null;
    }

    try {
      await updateProfile(currentUser, {
        displayName: `${firstName}`,
        photoURL: photoPath || null
      });
      console.log("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [firstName, selectedImage, auth]);

  const handleOTPChange = (value, index) => {
    if (value.length > 1) {
        const chars = value.split(''); 
        const newCode = [...otpCode];
        
        for (let i = 0; i < Math.min(chars.length, newCode.length); i++) {
          newCode[i] = chars[i];
        }
    
        setOtpCode(newCode);
    
        // If full code entered, auto-submit
        if (newCode.every((digit) => digit && digit !== '')) {
          handleOTPSubmit(newCode.join(''));
        } else {
          // Otherwise, focus the next empty field if needed
          const firstEmptyIndex = newCode.findIndex((c) => !c);
          if (firstEmptyIndex !== -1 && inputRefs.current[firstEmptyIndex]) {
            inputRefs.current[firstEmptyIndex].focus();
          }
        }
        return;
    }
    if (value && !/^\d$/.test(value)) return;
    const newCode = [...otpCode];
    newCode[index] = value;
    setOtpCode(newCode);
    if (value && index < 5) {
      setCurrentOTPIndex(index + 1);
      inputRefs.current[index + 1]?.focus();
    }
    // If all filled
    if (newCode.every((digit) => digit && digit !== '')) {
      handleOTPSubmit(newCode.join(''));
    }
  };

  const handleOTPSubmit = useCallback((otp) => {
    console.log("OTP Submitted:", otp);
    console.log(phoneConfirmationResult);
    phoneConfirmationResult.confirm(otp)
    .then((result) => {
      console.log("OTP confirmed");
      setViewState('verified');
    }).catch((error) => {
      console.error("Error confirming OTP:", error);
      alert("Invalid One Time Password");
      setViewState('phoneInput');
    });
  }, [phoneConfirmationResult]);

  const setUpRecaptha = useCallback(async () => {
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: 'invisible',
        callback: (response) => {
          console.log('reCAPTCHA solved:', response);
          setViewState('otpInput');
        }
      },
      auth,
    );
    await appVerifier.render();
    setRecaptchaVerifier(appVerifier);
    return appVerifier;
  }, [auth]);

  const handleSubmitPhone = useCallback(async () => {
    const number = selectedCountry.code + phoneNumber;
    if (!validatePhoneNumber(number)) {
      alert("Invalid phone number");
      return;
    }
    if (firstName === "") {
      alert("Please enter your first name");
      return;
    }

    if (lastName === "") {
      alert("Please enter your last name");
      return;
    }

    if (email === "" || !validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    const appVerifier = await setUpRecaptha(number);
    signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult) => {
        console.log("confirmationResult", confirmationResult)
        setPhoneConfirmationResult(confirmationResult);
        setViewState('otpInput');
      })
      .catch((error) => {
        console.error("error from signInWithPhoneNumber", error)
      });
  }, [selectedCountry, phoneNumber, firstName, lastName, email, auth, setUpRecaptha]);

  const onContinueClick = useCallback(() => {
    if (viewState === 'phoneInput') {
      handleSubmitPhone();
    } else if (viewState === 'otpInput') {
      // If user hasn't auto-submitted, they can manually continue
      if (otpCode.every((digit) => digit && digit !== '')) {
        handleOTPSubmit(otpCode.join(''));
      } else {
        // maybe alert incomplete code
      }
    } else if (viewState === 'verified') {
      console.log("saveFirebase")
      saveFirebase();
      console.log("saveFirebase done")
      fetch("https://vaicai.com/api/traveler/default/partial/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth": auth.currentUser.accessToken
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
        }),
      }).then((response) => {
        if (response.ok) {
          onComplete();
        }
      }).catch((error) => {
        console.error("Error submitting final data:", error);
        onComplete();
      });
    }
  }, [viewState, firstName, lastName, email, auth, onComplete, handleSubmitPhone, handleOTPSubmit, otpCode, saveFirebase]);

  useEffect(() => {
    if (viewState === 'verified') {
      // Blur all OTP inputs
      inputRefs.current.forEach((input) => {
        if (input) input.blur();
      });
      document.activeElement?.blur();
      window.scrollTo(0, 0);
      modalRef.current?.focus();
      onContinueClick();
    }
  }, [viewState, onContinueClick]);

  useEffect(() => {
    if (viewState === 'otpInput' && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [viewState]);


  const handleOTPFocus = (index) => {
    setCurrentOTPIndex(index);
    inputRefs.current[index]?.focus();
  }

  const renderOTPInputs = () => {
    const inputs = [];
    for (let i = 0; i < 6; i++) {
        if (i === 0) {
            inputs.push(
                <Input
                    key={i}
                    ref={(input) => (inputRefs.current[i] = input)}
                    value={otpCode[i]}
                    autoComplete="one-time-code"
                    type="text"
                    inputMode="numeric"
                    onInput={(e) => handleOTPChange(e.target.value, i)}
                    onFocus={() => handleOTPFocus(i)}
                    variant="outline"
                    textAlign="center"
                    fontSize="16px"
                    color="black"
                    width="42px"
                    height="50px"
                    borderBottom="2px solid"
                    borderColor={otpCode[i] ? "#5F22D9" : "gray.300"}
                />
            );
        } else {    
            inputs.push(
                <Input
                    key={i}
                    ref={(input) => (inputRefs.current[i] = input)}
                    value={otpCode[i]}
                    onChange={(e) => handleOTPChange(e.target.value, i)}
                    onFocus={() => handleOTPFocus(i)}
                    maxLength="1"
                    variant="outline"
                    textAlign="center"
                    fontSize="16px"
                    color="black"
                    width="42px"
                    height="50px"
                    borderBottom="2px solid"
                    borderColor={otpCode[i] ? "#5F22D9" : "gray.300"}
                    _focus={{
                        borderColor: "#5F22D9",
                        outline: "gray.300"
                    }}
                    // no autocomplete here
                />
            );
        }
    }

    return (
     <VStack width="100%" align="start" alignItems="flex-start">
        <Text fontSize="14px">One Time Password</Text>
        <HStack justify="center" spacing={3}>
          {inputs}
        </HStack>
     </VStack>
    );
  }

  let phoneContent;
  if (viewState === 'phoneInput') {
    phoneContent = (
      <VStack width="100%" align="start" alignItems="flex-start">
        <HStack width="100%" spacing={1}>
          <Text fontSize="14px" mb={1}>Phone Number</Text>
          <Text fontSize="12px" mb={1}>(required)</Text>
        </HStack>
        <HStack spacing={8}>
          <CountrySelect countryCodes={countryCodes} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
          <Input
            height="48px"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </HStack>
      </VStack>
    );
  } else if (viewState === 'otpInput') {
    phoneContent = renderOTPInputs();
  } else if (viewState === 'verified') {
    phoneContent = (
      <VStack width="100%" align="start" alignItems="flex-start">
        <Text fontSize="14px" mb={1}>Phone Number</Text>
        <Input
          height="48px"
          value={`${selectedCountry.code}${phoneNumber}`}
          isDisabled
        />
        <Text fontSize="12px" color="gray.500">Phone number verified. Cannot be changed.</Text>
      </VStack>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent ref={modalRef} width={isMobile ? "343px" : "436px"} tabIndex="-1">
        
        <ModalHeader textAlign="center" p={0} width="100%">
          <VStack alignItems="center" width="100%" p={0}>
            <HStack width="100%" justifyContent="space-between" alignItems="center" spacing={4}>
              <Box width="24px" /> {/* Placeholder */}
              <Text fontSize="16px" fontWeight="700" flexGrow={1} textAlign="center">
                  Sign Up
              </Text>
              <ModalCloseButton position="static" />
            </HStack>
            <Box height="1px" width="100%" bg="gray.200" />
          </VStack>
        </ModalHeader>
        
        <ModalBody width="100%">
          <VStack spacing={6} align="start" width="100%" alignItems="flex-start">
            <VStack spacing={2} height="56px" alignItems="flex-start">
              <Text fontSize="16px" fontWeight="700" textAlign="start">Account Information</Text>
              <Text fontSize="14px" textAlign="start">Please add your information below</Text>
            </VStack>

            <ProfilePhotoSection auth={auth} app={app} selectedImage={selectedImage} onImageSelected={setSelectedImage} onRemoveImage={handleRemoveImage} />
            
            <VStack width="100%" align="start" alignItems="flex-start">
              <HStack width="100%" spacing={1}>
                <Text fontSize="14px" mb={1}>First Name</Text>
                <Text fontSize="12px" mb={1}>(required)</Text>
              </HStack>
              <Input
                height="48px"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </VStack>

            <VStack width="100%" align="start" alignItems="flex-start">
              <HStack width="100%" spacing={1}>
                <Text fontSize="14px" mb={1}>Last Name</Text>
                <Text fontSize="12px" mb={1}>(required)</Text>
              </HStack>
              <Input
                height="48px"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </VStack>

            <VStack width="100%" align="start" alignItems="flex-start">
              <HStack width="100%" spacing={1}>
                <Text fontSize="14px" mb={1}>Email Address</Text>
                <Text fontSize="12px" mb={1}>(required)</Text>
              </HStack>
              <Input
                height="48px"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </VStack>

            {phoneContent}

          </VStack>
        </ModalBody>
        
        <ModalFooter justifyContent="flex-end" p={0} width="100%">
          <VStack spacing={4} width="100%">
            <Box width="100%" bg="gray.200" height="1px"/>
            <Button
              p={4}
              width={isMobile ? "295px" : "388px"}
              mb={4}
              bg="#5F22D9"
              color="white"
              variant="solid"
              size="lg"
              onClick={onContinueClick}
            >
              Continue
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}