import React from 'react';
import {
    Input,Button,Text,
    VStack, Modal,
    ModalOverlay, ModalContent,
    ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton,
    FormControl, FormLabel, Box, HStack, Container, Image, Heading, Spacer, Select, Flex
  } from '@chakra-ui/react';
import './ReferralModal.css';
import '../components/Login/EnterPhone';
import Background from './Assets/referral-modal-bg.jpg'
import logo from './Assets/logo.svg'
import EnterPhone from '../components/Login/EnterPhone';
import SignUpModal from '../components/SignUpModal';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';
import WelcomeModal from './WelcomeModal';
// import 'firebase/auth';

import appstore from './Assets/download_ios.png';
import DownloadAppModal from './DownloadAppModal';
import DownloadBackground from './Assets/download_bg.jpeg'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { auth , app, fire_tracking_event} from '../firebase/firebase';
import { onAuthStateChanged } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


class ReferralModal extends React.Component {
    constructor(props) {
        super(props);
        console.log("is open", props.isOpen)
        console.log("referral code", props.referralCode);
        this.state = {
            code: this.props.code,
            viewState: 'checkingAuth',
            referrerName: '',
            firstName: '',
            email: '',
            phoneNumber: '',
            lastName: '',
            isEmailValid: true,
            isPhoneNumberValid: true,
            isLoading: false,
            phoneState: 'input',
            confirmationResult: null,
            recaptchaReponse: null,
            referralCode: this.props.referralCode
        };
       this.auth = auth;
    }

    validateEmail = (email) => {
        if (email === "" ){
            return true;
        }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    }

    handleEmailChange = (e) => {
        console.log(this.state)
        this.setState({ email: e.target.value });
        this.setState({ isEmailValid: this.validateEmail(e.target.value) });
    }

    validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
    }

    handleDOBChange = (e) => {
        this.setState({ dob: e.target.value, isDOBValid: this.validateDOB(e.target.value)})
    }

    isLeapYear(year) {
        // A year is a leap year if it is divisible by 4, except for end-of-century years which must be divisible by 400
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            return 1; // Adding 1 extra day to February for leap years
        }
        return 0;
    }

    isValidDay(day, month, year) {
        const monthDays = [31, 28 + this.isLeapYear(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    
        // Check if day is within the valid range for the month
        if (day < 1 || day > monthDays[month - 1]) {
            return false;
        }
    
        return true;
    }
    
    

    validateDOB(dateStr) {
        // Check if the input string length is exactly 8
        if (dateStr.length !== 8) {
            return false;
        }
    
        // Extract day, month, and year from the string
        let day = parseInt(dateStr.substring(0, 2), 10);
        let month = parseInt(dateStr.substring(2, 4), 10);
        let year = parseInt(dateStr.substring(4, 8), 10);
    
        // Check if day, month, and year are numeric and not NaN
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return false;
        }
    
        // Check if month is valid (1-12)
        if (month < 1 || month > 12) {
            return false;
        }
    
        // Check if day is valid for the month, considering leap years for February
        return this.isValidDay(day, month, year);
    }
    
    

    handlePhoneNumberChange = (e) => {
        this.setState({ phoneNumber: e.target.value });
        this.setState({ isPhoneNumberValid: this.validatePhoneNumber(e.target.value) });
    }

    handleOTPSubmit = (otp) => {
        this.setState({ phoneState: 'loading' });
        console.log(otp)
        console.log(this.state.confirmationResult)
        this.state.confirmationResult.confirm(otp)
        .then((result) => {
            // User signed in successfully.
            console.log("hey")
            this.setState({ phoneState: 'success', viewState: 'loggedIn' });
            console.log("there")
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            alert("Invalid OTP");
            this.setState({ phoneState: 'input' });
        });
    }

    calculateAge = (birthDate) => {
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
    
        // Check if the current month is before the birth month or
        // if it's the same month, check if the day is on or before today
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }

    componentWillUnmount() {
        if (this.unsubscribeFromAuth) {
            this.unsubscribeFromAuth(); // Unsubscribe to avoid memory leaks
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.viewState !== prevState.viewState) {
            this.componentDidMount();
        }
    }

    submitReferralForm = (formData) => {
        console.log("submitting form", formData);
        // NEED TO TEST THIS
        if (!this.state.isDOBValid) { 
            console.log(this.state)
            alert("Please enter your date of birth in the correct format")
            return;
        } 
        let day = parseInt(formData.dob.substring(0, 2), 10);
        let month = parseInt(formData.dob.substring(2, 4), 10);
        let year = parseInt(formData.dob.substring(4, 8), 10);
        if (this.calculateAge(new Date(year, month - 1, day)) < 18) {
            alert("You must be 18 years or older to use the VaicAI platform")
            return;
        }

        fetch(`https://www.vaicai.com/api/traveler/default/`, // 
                    {
                        method: 'POST',
                        headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', // Add this line
                        'auth': this.state.currentUser.accessToken
                        },
                        body: JSON.stringify({ // Convert the body to a JSON string
                        first_name: formData.firstName,
                        last_name: formData.lastName,
                        email: formData.email,
                        born_on: formData.dob,
                        gender: formData.gender,
                        title: formData.title,
                        // Include all the data you want to send in JSON format
                        })
                    }
                    )
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                                viewState: "download",
                                referrerName: data.referrerName,
                                referralMessage: data.message
                            })
                        
                    })
                    .catch(error => {
                        alert("Error creating Profile!", error)
                            // toast({
                            //     title: "Error",
                            //     description: "There was an error loading the referral",
                            //     status: "error",
                            //     duration: 5000,
                            //     isClosable: true,
                            // });
                    });
        //this.setState({ viewState: 'download' }); // skip to download for now
    }

    componentDidMount() {
        console.log("component did mount", this.state.viewState)
        
        switch (this.state.viewState) {
            case 'checkingAuth':
                fire_tracking_event('referral.signup_start.' + this.state.referralCode);
                this.unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
                    if (user) {
                      // User is signed in, see docs for a list of available properties
                      // https://firebase.google.com/docs/reference/js/firebase.User
                      if (this.state.viewState === "auth") { return; }
                      this.setState({ currentUser: user, viewState: 'loggedIn' });
                    } else {
                      // User is signed out
                      this.setState({ currentUser: null, viewState: 'loggedOut' });
                    }
                  });
                break;
            case 'loggedOut':
                if (this.state.currentUser !== null) {
                    // log in
                    this.setState({'viewState': 'loggedIn'});
                    console.log("logged out")
                }
                break;
            case 'loggedIn':
                console.log("logged in wtf bro")
                this.setState({ viewState: 'loading' });
                break;
            case 'loading':
                // if (!this.state.currentUser.accessToken) {
                //     alert(`WTF ${this.state.currentUser}`)
                // }
                if (!auth.currentUser) {
                    this.setState({ viewState: 'loggedOut' });
                    return;
                }
                console.log(auth.currentUser);
                console.log(auth.currentUser.accessToken);
                fetch(`https://www.vaicai.com/api/social/referral/${this.state.referralCode}/`, // vaicai.com
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'auth': auth.currentUser.accessToken
                            }
                        }
                    )
                    .then(response => response.json())
                    .then(data => {
                        console.log("data", data)
                        if (!data.success) {
                            console.log('the fuck', data.message)
                            if (data.result === "invalid_code") {
                                this.setState({ viewState: 'invalid_referral_code' });
                            } else if (data.result === "already_claimed") {
                                this.setState({
                                    viewState: 'already_claimed',
                                    referrerName: '',
                                    referralMessage: data.message
                                })
                            }
                        } else {
                            this.setState({
                                viewState: "loaded",
                                referrerName: data.referrerName,
                                referralMessage: data.message
                            })
                        }
                    })
                    .catch(error => {
                        alert("There was an issue trying to get your referral information. Please refresh the page to try again.")
                        console.error('Error:', error);
                    });

                break;
            default:
                break;
        }
    }

    render() {
        console.log("rendering", this.state.viewState)
        if (this.state.viewState === 'loading') {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Loading Referral!</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4}>
                                <p>Loading...</p>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={this.props.onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                </div>
            )
        } else if (this.state.viewState === 'loggedOut') {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <WelcomeModal 
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                    referrerName={this.state.referrerName}
                    onLoginClick={() => {this.setState({viewState: 'auth'})}} 
                    onSignUpClick={() => {this.setState({viewState: 'auth'})}}
                />
                </div>
            );
        } else if  (this.state.viewState === "auth") {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                  <SignUpModal
                  auth={auth}
                  app={app}
                  isOpen={this.props.isOpen}
                  onClose={this.props.onClose}
                  onComplete={() => {
                    fire_tracking_event(`referral.signup_complete.${this.state.referralCode}`)
                    console.log("onComplete", this.state.viewState)
                    this.setState({viewState: 'loading'})
                  }}
                />
                                
                        
                </div>
            );
        }
        else if (this.state.viewState === 'invalid_referral_code') {

            return (
                <div class="justify-center flex" id="recaptcha-container">
                <DownloadAppModal 
                    message={'It looks like you may be using an invalid referral code! Visit the link you were sent again to give it another try!'} 
                    isOpen={this.props.isOpen} 
                    onClose={this.props.onClose}
                    referralCode={this.state.referralCode}
                />
                </div>
            );
        } else if (this.state.viewState === 'already_claimed') {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <DownloadAppModal 
                    message={this.state.referralMessage} 
                    isOpen={this.props.isOpen} 
                    onClose={this.props.onClose}
                    referralCode={this.state.referralCode}
                />
                </div>
            );
        } else if (this.state.viewState === 'download') {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <DownloadAppModal 
                    message={this.state.referralMessage} 
                    isOpen={this.props.isOpen} 
                    onClose={this.props.onClose}
                    referralCode={this.state.referralCode}
                />
                </div>
            );
        } else if (this.state.viewState === 'loaded') {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <DownloadAppModal 
                    message={this.state.referralMessage} 
                    isOpen={this.props.isOpen} 
                    onClose={this.props.onClose}
                    referralCode={this.state.referralCode}
                />
                </div>
            );
        } else {
            return (
                <div class="justify-center flex" id="recaptcha-container">
                <WelcomeModal
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                    referrerName={this.state.referrerName}
                    onLoginClick={() => {
                        this.setState({viewState: 'auth'})
                        fire_tracking_event(`referral.login_clicked.${this.state.referralCode}`)
                    }}
                    onSignUpClick={() => {
                        this.setState({viewState: 'auth'})
                        fire_tracking_event(`referral.signup_clicked.${this.state.referralCode}`)
                    }}
                />
                </div>
            );
        }
    }
}

// export default ReferralModal;


export default  ReferralModal