import { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  Spacer
} from '@chakra-ui/react';
import CountryFlag from 'react-country-flag';

function CountrySelect({ countryCodes, selectedCountry, setSelectedCountry }) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredCountries = countryCodes.filter((country) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Menu>
      <MenuButton as={Button} p={0} bg="transparent" border="none">
        <HStack
          bg="#5F22D9"
          borderRadius="8px"
          alignItems="center"
          justifyContent="space-between"
          px={selectedCountry.code.length > 3 ? 2 : 4}
          width="74px"
          height="48px"
        >
          <CountryFlag
            countryCode={selectedCountry.flagCode}
            svg
            style={{ width: '1.2em', height: '1.2em' }}
          />
          <Text fontSize="14px" color="white">
            {selectedCountry.code}
          </Text>
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList zIndex="popover" maxH="200px" overflowY="auto" p={0}>
          {/* Add a search bar at the top */}
          <VStack p={2} align="stretch" spacing={2}>
            <Input
              placeholder="Search country..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="sm"
            />
          </VStack>

          {filteredCountries.map((country) => (
            <MenuItem
              key={country.flagCode}
              onClick={() => setSelectedCountry(country)}
            >
              <HStack alignItems="center" spacing={2} height="48px">
                <CountryFlag
                  countryCode={country.flagCode}
                  svg
                  style={{ width: '1.2em', height: '1.2em' }}
                />
                <Text fontSize="14px">
                  {country.name} ({country.code})
                </Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}

export default CountrySelect;