import React from "react";
import {
  Input, Button, Text,
  VStack, Portal,
  FormControl, FormLabel, Box,
  HStack, Flex, MenuButton, Menu, MenuList,
  MenuItem, Spacer, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CountryFlag from 'react-country-flag';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import EnterOTP from "./EnterOTP";
import { app, auth }    from '../../firebase/firebase';


const countryCodes = [
  { name: 'United States', code: '+1', flagCode: 'US' },
  { name: 'Canada', code: '+1', flagCode: 'CA' },
  { name: 'United Kingdom', code: '+44', flagCode: 'GB' },
  { name: 'Australia', code: '+61', flagCode: 'AU' },
  { name: 'New Zealand', code: '+64', flagCode: 'NZ' },
  { name: 'India', code: '+91', flagCode: 'IN' },
  { name: 'Brazil', code: '+55', flagCode: 'BR' },
  { name: 'South Africa', code: '+27', flagCode: 'ZA' },
  { name: 'Nigeria', code: '+234', flagCode: 'NG' },
  { name: 'Kenya', code: '+254', flagCode: 'KE' },
  { name: 'Mexico', code: '+52', flagCode: 'MX' },
  { name: 'Germany', code: '+49', flagCode: 'DE' },
  { name: 'France', code: '+33', flagCode: 'FR' },
  { name: 'Italy', code: '+39', flagCode: 'IT' },
  { name: 'Spain', code: '+34', flagCode: 'ES' },
  { name: 'China', code: '+86', flagCode: 'CN' },
  { name: 'Japan', code: '+81', flagCode: 'JP' },
  { name: 'Singapore', code: '+65', flagCode: 'SG' },
  { name: 'Pakistan', code: '+92', flagCode: 'PK' },
  { name: "Trinidad and Tobago", code: "+1", flagCode: "TT" },
  { name: "Jamaica", code: "+1", flagCode: "JM" },
  { name: "Barbados", code: "+1", flagCode: "BB" },
  { name: "Bahamas", code: "+1", flagCode: "BS" },
  { name: "Grenada", code: "+1", flagCode: "GD" },
  { name: "Saint Lucia", code: "+1", flagCode: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "+1", flagCode: "VC" },
  { name: "Antigua and Barbuda", code: "+1", flagCode: "AG" },
  { name: "Dominica", code: "+1", flagCode: "DM" },
  { name: "Saint Kitts and Nevis", code: "+1", flagCode: "KN" },
  { name: "Saint Martin", code: "+590", flagCode: "MF" },
];

class EnterPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: countryCodes[0],
      phoneNumber: '',
      isModalOpen: true, // Make sure the modal is open to display the form
      isPhoneNumberValid: true,
      app: props.app,
      confirmationResult: props.confirmationResult,
      viewState: props.state,
      recaptchaReponse: props.recaptchaReponse,
      code: props.code
    };

    this.onConfirmationResult = props.onConfirmationResult.bind(this);
    this.onRecaptchaResponse = props.onRecaptchaResponse.bind(this);
    this.onSubmitOTP = props.onSubmitOTP.bind(this);
    this.onSignInSuccess = props.onSignInSuccess.bind(this);

    this.auth = auth;
    console.log('auth', this.auth)
    console.log('referral code', this.state.code)
  }

  validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  }

  handleInputChange = (event) => {
    this.setState({ phoneNumber: event.target.value, isPhoneNumberValid: this.validatePhoneNumber(event.target.value) });
  };

  selectCountry = (country) => {
    this.setState({ selectedCountry: country });
  };

  handleSubmit = async () => {
    this.setState({ viewState: 'loading' });
    const number = this.state.selectedCountry.code + this.state.phoneNumber;
    console.log('number', number)
    this.setUpRecaptha(number);
    const appVerifier = this.recaptchaVerifier;

    signInWithPhoneNumber(this.auth, number, appVerifier)
      .then((confirmationResult) => {
        this.setState({ confirmationResult: confirmationResult, viewState: 'otp' });
        this.onConfirmationResult(confirmationResult)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setUpRecaptha() {
    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      "recaptcha-container",
      {
        size: 'invisible',
        appVerificationDisabledForTesting: true,
        callback: (response) => {
          console.log('reCAPTCHA solved:', response);
          this.onRecaptchaResponse(response);
          this.setState({ viewState: 'otp' });
        }
      },
    );
    this.recaptchaVerifier = recaptchaVerifier;
    recaptchaVerifier.render();
  }

  render() {
    const { selectedCountry, confirmationResult, viewState } = this.state;

    let content;
    if (viewState === 'input') {
      content = (
        <Modal isOpen={this.state.isModalOpen} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent maxW="350px" borderRadius="12px" overflow="hidden">
            <ModalHeader p={0}>
              <VStack alignItems="center"> 
                <Spacer />
                <Box p={4} width="100%" textAlign="center">
                  <Box fontSize="lg" fontWeight="bold">
                    Sign Up
                  </Box>
                </Box>
                <Spacer />
                <Box height="1px" width="100%" bg="gray.200" />
                <ModalCloseButton position="absolute" right="8px" top="8px" />
              </VStack>
            </ModalHeader>
            <ModalBody px={6} pb={6}>
                <VStack alignItems='start'>
                <Text fontSize="sm" fontWeight="medium" mb={2}>Sign up to claim your referral bonus!</Text>
                {/* <Box height="1px" width="100%" bg="gray.200" /> */}
                <Text fontSize="md" fontWeight="bold" mb={2}>Phone Number</Text>
              </VStack>
              <FormControl>
                <FormLabel fontSize="sm" mb={1}>Please enter your phone number</FormLabel>
                <HStack>
                  <Menu>
                    <MenuButton as={Button} bg="#5F22D9" color="white" px={3}>
                      <CountryFlag
                        countryCode={selectedCountry.flagCode}
                        svg
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          marginRight: '8px',
                        }}
                      />
                      {selectedCountry.code}
                    </MenuButton>
                    <Portal>
                        <MenuList zIndex="popover"  maxH="200px" overflowY="auto"> {/* Ensure a high zIndex if needed */}
                        {countryCodes.map((country) => (
                            <MenuItem key={country.code} onClick={() => this.selectCountry(country)}>
                            <CountryFlag
                                countryCode={country.flagCode}
                                svg
                                style={{ width: '1.2em', height: '1.2em', marginRight: '8px' }}
                            />
                                {country.name} ({country.code})
                            </MenuItem>
                        ))}
                    </MenuList>
                  </Portal>
                  </Menu>
                  <Input placeholder="Phone Number" onChange={this.handleInputChange} />
                </HStack>
              </FormControl>
            </ModalBody>
            <ModalFooter px={0} pb={6}>
              <VStack alignItems="center" width="100%">
              <Box height="1px" width="100%" bg="gray.200" />
              <Button
                bg="#5F22D9"
                color="white"
                borderRadius="8px"
                onClick={() => this.handleSubmit()}
                height="48px"
                width="100%"
              >
                Continue
                </Button>
              </VStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    } else {
      // 'otp' state
      content = (
        <EnterOTP confirmationResult={confirmationResult} onSubmitOTP={this.onSubmitOTP}/>
      );
    }

    return (
      <>
        <div id="recaptcha-container"></div>
        {content}
      </>
    );
  }
}

export default EnterPhone;