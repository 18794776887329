import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Image,
  Text,
  VStack,
  Button,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import Background from './Assets/referral-modal-bg.jpg';
import logo from './Assets/logo.svg';

const WelcomeModal = ({ isOpen, onClose, referrerName, onSignUpClick, onLoginClick }) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none">
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent
        borderRadius="lg"
        overflow="hidden"
        mx={4}
        my={4}
        width={["calc(100% - 32px)", "calc(100% - 32px)", "612px"]}
        maxWidth="612px"
        p={0} // remove default padding
      >
        <ModalCloseButton size="lg" color="white" zIndex="10" />
        {/* Image container: use full width and a fixed height */}
        <Box position="relative" width="100%" height={["472px","472px","496px"]}>
          <Image
            src={Background}
            alt="Background"
            width="100%"
            height="100%"
            objectFit="cover"
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            textAlign="center"
            px={[2,4]}
            maxWidth="80%"
          >
            <Flex justifyContent="center" width="100%" mb={[8,8,20]}>
              <Image src={logo} alt="Company Logo" />
            </Flex>
            <Text fontSize={["24px","32px"]} fontWeight="bold">
              Welcome to VaicAI
            </Text>
            {referrerName && (
              <Text fontSize={["16px","20px"]} mt={2}>
                Your Friend {referrerName} Referred You! Sign up to claim your travel bonus!
              </Text>
            )}
            {!referrerName && (
              <Text fontSize={["16px","20px"]} mt={2}>
                You've been referred to VaicAI. Sign up to claim your travel bonus!
              </Text>
            )}
          </Box>
        </Box>

        {/* Button section */}
        <VStack p={4} spacing={4} bgColor="white">
          <Button
            p={4}
            variant="solid"
            size="lg"
            width="full"
            bg="#5F22D9"
            color="white"
            onClick={onSignUpClick}
          >
            Sign Up
          </Button>
          <Button
            p={4}
            variant="solid"
            bg="#F9C200"
            color="black"
            size="lg"
            width="full"
            onClick={onLoginClick}
          >
            Log in
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal