import React from 'react';
import {
  Input,
  Button,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  useToast,
  Box,
  HStack,
  Container,
  Heading,
  Flex,
  IconButton
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

class EnterOTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: Array(6).fill(''),
      currentIndex: 0,
      resendTimer: 48 // Example timer, you can handle logic as needed
    };
    this.onSubmitOTP = props.onSubmitOTP.bind(this);
  }

  // Handle input changes
  handleChange = (value, index) => {
    const newCode = [...this.state.code];
    // Only allow digits
    if (value && !/^\d$/.test(value)) return;

    newCode[index] = value;
    this.setState({ code: newCode });

    // Move focus to the next input if filled
    if (value && index < 5) {
      this[`inputRef${index + 1}`]?.focus();
    }
  };

  handleFocus = (index) => {
    this.setState({ currentIndex: index });
  };

  renderInputs() {
    const inputs = [];

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <Input
          key={i}
          ref={(input) => (this[`inputRef${i}`] = input)}
          value={this.state.code[i]}
          onChange={(e) => this.handleChange(e.target.value, i)}
          onFocus={() => this.handleFocus(i)}
          maxLength="1"
          variant="unstyled"
          textAlign="center"
          fontSize="2xl"
          width="40px"
          height="50px"
          borderBottom="2px solid"
          borderColor={this.state.code[i] ? "#5F22D9" : "gray.300"}
          _focus={{
            borderColor: "#5F22D9",
            outline: "none"
          }}
        />
      );
    }

    return (
      <HStack justify="center" spacing={4}>
        {inputs}
      </HStack>
    );
  }

  render() {
    const isComplete = !this.state.code.includes('');

    return (
      <Modal isOpen={true} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px" borderRadius="12px">
          {/* Header with back button and close button */}
          <ModalHeader p={4}>
            <Flex alignItems="center">
              <IconButton
                variant="ghost"
                aria-label="Back"
                icon={<ArrowBackIcon />}
                mr={2}
                onClick={() => { /* handle back action */ }}
              />
              <Box flex="1" textAlign="center" fontSize="lg" fontWeight="bold">
                Sign Up
              </Box>
              <ModalCloseButton position="absolute" right="8px" top="8px" />
            </Flex>
          </ModalHeader>

          <ModalBody px={6} pb={6}>
            <Heading fontSize="lg" mb={1}>Enter Code</Heading>
            <Text fontSize="sm" color="gray.600" mb={6}>
              We have sent a code to your phone
            </Text>

            <FormControl>
              <VStack spacing={6}>
                {this.renderInputs()}

                <Text fontSize="sm" color="gray.600">
                  Didn’t get a code? Resend in <Text as="span" color="#5F22D9" fontWeight="bold">{this.state.resendTimer}s</Text>
                </Text>
              </VStack>
            </FormControl>
          </ModalBody>

          <ModalFooter px={6} pb={6}>
            <Button
              bg="#5F22D9"
              color="white"
              borderRadius="8px"
              height="48px"
              width="100%"
              onClick={() => this.onSubmitOTP(this.state.code.join(''))}
              isDisabled={!isComplete}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

export default EnterOTP;