import { Button } from '@chakra-ui/react';

export function SimpleBorderButton({ children, ...props }) {
  return (
    <Button
      variant="outline"
      borderColor="gray.300"
      borderWidth="1px"
      bg="transparent"
      color="black"
      fontSize="12.8px"
      borderRadius="8px"
      fontWeight="400"  // adjust as needed
      px={4}
      py={2}
      _hover={{
        bg: "gray.100",
      }}
      {...props}
    >
      {children}
    </Button>
  );
}