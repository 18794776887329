import React from 'react';
import {
    Input,Button,Text,
    VStack, Modal,
    ModalOverlay, ModalContent,
    ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton,
    FormControl, FormLabel, Box, HStack, Container, Image, Heading, Spacer, Select, Flex, SimpleGrid
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import ContentfulImageView from '../contentful/ContentfulImageView.js';
import constants from '../../constants';
import MapView from '../MapView';
import ItineraryView from './ItineraryView';
import EnterPhone from '../Login/EnterPhone.js';
import { onAuthStateChanged } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";
import WelcomeModal from '../WelcomeModal';
import DownloadAppModal from '../DownloadAppModal';
import { auth, app } from '../../firebase/firebase';

class TripView extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            trip: null,
            state: "loading",
            code: props.code,
            tripType: props.tripType,
            phoneNumber: '',
            lastName: '',
            isEmailValid: true,
            isPhoneNumberValid: true,
            isLoading: false,
            phoneState: 'input',
            confirmationResult: null,
            recaptchaReponse: null,
            viewState: 'loading', // Set initial state to 'loggedOut'
            referrerName: '', // Make sure this is set somewhere
        }; 
        this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
    }

    componentDidMount() {
        if (!this.state.isOpen) {
            return;
        }
        if (this.state.tripType === constants.TripType.GROUP) {
            fetch(`https://www.vaicai.com/social/web/trip/${this.state.code}/`)
                .then(response => response.json())
                .then(data => {
                    this.setState(
                    {
                        trip: data,
                        welcome_message: data.welcome_message,
                        state: "loaded"
                    })
                    console.log(this.state.trip);
                    console.log(this.state.welcome_message);
                    console.log(data)
                })
                .catch(error => console.error('Error fetching data:', error));
            
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.state.state)
        console.log(prevState.state)
        switch (this.state.state) {
            case "login":
                console.log("login")
                this.unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
                if (user) {
                  // User is signed in, see docs for a list of available properties
                  // https://firebase.google.com/docs/reference/js/firebase.User
                  this.setState({ currentUser: user, state: 'loggedIn' });
                } else {
                  // User is signed out
                  this.setState({ currentUser: null, state: 'loggedOut' });
                }
              });
              break;
            case "loggedOut":
                this.unsubscribeFromAuth();
                break;
            case "loading":
                break;
            case "loggedIn":
                this.unsubscribeFromAuth();
                fetch(`https://www.vaicai.com/social/shared/trip/${this.state.code}/`, 
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'auth': this.state.currentUser.accessToken
                        }
                    }
                )
                .then(response => response.json())
                .then(data => {
                    this.setState({ trip: data, state: "download" });
                })
                .catch(error => console.error('Error fetching data:', error));
                break;
            default:
                break;
        }

        if (prevState.state !== this.state.state) {
            console.log('ViewState changed to:', this.state.state);
        }
    }

    componentWillUnmount() {
        this.unsubscribeFromAuth(); // Unsubscribe to avoid memory leaks
    }
    
    handleOTPSubmit = (otp) => {
        this.setState({ phoneState: 'loading' });
        console.log(otp)
        console.log(this.state.confirmationResult)
        this.state.confirmationResult.confirm(otp)
        .then((result) => {
            // User signed in successfully.
            console.log("hey")
            this.setState({ phoneState: 'success', state: 'loggedIn' });
            console.log("there")
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            alert("Invalid OTP");
            this.setState({ phoneState: 'input' });
        });
    }

    onDelayedTabChange = (index) => {
        console.log(`Tab ${index} fully activated in TripView after delay`);
        this.setState({ state: 'login' });
        console.log('ViewState changed to: login');
    }

    handleSignUpClick = () => {
        this.setState({ state: 'auth' });
        console.log('Sign up clicked, viewState set to: auth');
    }

    handleLoginClick = () => {
        this.setState({ state: 'auth' });
        console.log('Login clicked, viewState set to: auth');
    }

    render() {
        console.log('Rendering. Current viewState:', this.state.state);

        switch (this.state.state) {
            case 'loggedOut':
                return (
                    <WelcomeModal
                        isOpen={true} // Always open when in 'loggedOut' state
                        onClose={() => {}} // Empty function as we don't want to close it
                        message={this.state.welcome_message}
                        onSignUpClick={this.handleSignUpClick}
                        onLoginClick={this.handleLoginClick}
                    />
                );
            case 'auth':
                // Render your auth component here
                return <div id="recaptcha-container">
                    <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <EnterPhone
                                state={this.state.phoneState}
                        app={app}
                        confirmationResult={this.state.confirmationResult}
                        recaptchaReponse={this.state.recaptchaReponse}
                        onRecaptchaResponse={(recaptchaReponse) => {
                            this.setState({recaptchaReponse: recaptchaReponse, phoneState: 'otp'})
                        }}
                        onConfirmationResult={(confirmationResult) => {
                            console.log("onConfirmationResult")
                            console.log(confirmationResult)
                            this.setState({confirmationResult: confirmationResult, phoneState: 'otp'})
                        }}
                        onSubmitOTP={this.handleOTPSubmit}
                        onSignInSuccess={() => {
                            this.setState({phoneState: 'download'})
                        }}
                        code={this.state.code}
                    />
                </ModalContent>
                </Modal>
                </div>
            case 'download':
                return (
                    <DownloadAppModal
                        isOpen={this.props.isOpen}
                        onClose={this.props.onClose}
                        message="Download the app to see your full itinerary!"
                    />
                );
            case 'loggedIn':
                return (
                    <DownloadAppModal
                        isOpen={this.props.isOpen}
                        onClose={this.props.onClose}
                        message="Download the app to see your full itinerary!"
                    />
                );
            case 'login':

                return <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <Text>Loading...</Text>
                    </ModalContent>
                </Modal>
            default:
                return (
                    <div className="justify-center flex" id="recaptcha-container">
                        <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
                            <ModalOverlay />
                            <ModalContent
                                maxWidth={["90%", "80%", "70%", "612px"]}
                                width="100%"
                                mx={4}
                                // my={4 }
                                overflow="hidden"
                            >
                                <ModalBody overflow="auto" p={0}>
                                    {this.state.state === "loading" && (
                                        <ModalHeader>Loading Trip...</ModalHeader>
                                    )} 
                                    {(this.state.state === "loaded" || this.state.state === "loggedIn") && (
                                        <VStack spacing={6} align="stretch">
                                            <Box position="relative">
                                            {this.state.trip.image_id != null && (
                                                        <ContentfulImageView
                                                            spaceId={constants.CONTENTFUL_SPACE_ID}
                                                            accessToken={constants.CONTENTFUL_ACCESS_TOKEN}
                                                            assetId={this.state.trip.image_id}
                                                            placeholder={<div>Loading...</div>}
                                                            width={"100%"}
                                                            height={500}
                                                            objectFit="cover"
                                                        />
                                                    )}
                                              
                                            <Box position="absolute" bottom="22px" left="20px" align="center">
                                                <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                                                    {this.state.trip.name}
                                                </Text>
                                            </Box>
                                            </Box>
                                            {/* Dates and Flights */}
                                            {this.state.trip.startDate && this.state.trip.endDate && (
                                            <Box p={6}>
                                                <Flex justify="space-between" align="center">
                                                <VStack align="start">
                                                    <Text fontWeight="bold">Arrive</Text>
                                                    <Text>{this.state.trip.startDate}</Text>
                                                </VStack>
                                                <VStack align="end">
                                                    <Text fontWeight="bold">Depart</Text>
                                                    <Text>{this.state.trip.endDate}</Text>
                                                </VStack>
                                                </Flex>
                                            </Box>
                                            )}
                                            {this.state.trip.flight_offer_id != null && this.state.trip.flight_offer_id.length > 0 && (
                                                <Box mt={4}>
                                                <HStack justify="space-between">
                                                    <Text fontWeight="bold">Flights</Text>
                                                    <Button size="sm" variant="link">
                                                    See all
                                                    </Button>
                                                </HStack>
                                                {/* Stub for flight information */}
                                                <Box mt={2} p={4} border="1px solid #E2E8F0" borderRadius="md">
                                                    {/* Flight Information */}
                                                    <Text>Flight Information Here</Text>
                                                </Box>
                                                </Box>
                                            )}
                                            {/* Location Section */}
                                            <Box>
                                            <MapView latitude={34.0522} longitude={-118.2437} width={"100%"} height={300} />
                                            </Box>
                                            {/* Accommodation Section */}
                                            {this.state.trip.hotel_offer_id != null && this.state.trip.hotel_offer_id.length > 0 && (
                                            <Box p={6}>
                                                <HStack justify="space-between">
                                                <Text fontWeight="bold">Accommodation</Text>
                                                <Button size="sm" variant="link">
                                                    See all
                                                </Button>
                                                </HStack>
                                                <SimpleGrid columns={[1, null, 2]} spacing={4} mt={4}>
                                                <Box border="1px solid #E2E8F0" borderRadius="md" p={4}>
                                                    {/* Stub for Accommodation Card */}
                                                    <Text>Accommodation 1</Text>
                                                </Box>
                                                <Box border="1px solid #E2E8F0" borderRadius="md" p={4}>
                                                    {/* Stub for Accommodation Card */}
                                                    <Text>Accommodation 2</Text>
                                                </Box>
                                                </SimpleGrid>
                                            </Box>
                                            )}
                                            {/* Activities Section */}
                                            {this.state.trip.itinerary && (
                                                <Box p={6}>
                                                <Text fontWeight="bold" fontSize="2xl">Activities</Text>
                                                <ItineraryView itinerary={this.state.trip.itinerary} onDelayedTabChange={this.onDelayedTabChange} />
                                                </Box>
                                            )}
                                        </VStack>
                                    )}
                                    {/* {this.state.state === "auth" && (
                                        <ModalBody>
                                            <EnterPhone
                                                state={this.state.phoneState}
                                                app={app}
                                                confirmationResult={this.state.confirmationResult}
                                                recaptchaReponse={this.state.recaptchaReponse}
                                                onRecaptchaResponse={(recaptchaReponse) => {
                                                    this.setState({recaptchaReponse: recaptchaReponse, phoneState: 'otp'})
                                                    console.log("pls")
                                                }}
                                                onConfirmationResult={(confirmationResult) => {
                                                    console.log("onConfirmationResult")
                                                    console.log(confirmationResult)
                                                    this.setState({confirmationResult: confirmationResult, phoneState: 'otp'})
                                                }}
                                                onSubmitOTP={this.handleOTPSubmit}
                                                onSignInSuccess={() => {
                                                    this.setState({phoneState: 'download'})
                                                }}
                                                code={this.state.code}
                                            />
                                        </ModalBody>
                                    )} */}
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </div>
                );
        }
    }
}

export default TripView;

{/* <div>
      <ContentfulImageView
        spaceId="your_space_id"
        accessToken="your_access_token"
        assetId="your_asset_id"
        placeholder={<div>Loading...</div>}
        width={320}
        height={168}
      />
    </div> */}